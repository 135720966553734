.hizmetler h1{
    font-size: 22px;
    text-align: center;
}
.hizmetler h2{
    font-size: 32px;
    text-align: center;
    font-weight: 600;
}
.hizmetler a{
    display: block;
}
.hizmetler a:hover{
    transform: scale(1.05);
}
.hizmetler a.hizmet_1:hover .hizmet_card .card-footer{
    background-color: #7433ff;
    color: #fff;
}
.hizmetler a.hizmet_2:hover .hizmet_card .card-footer{
    background-color: #ffb236;
    color: #fff;
}
.hizmetler a.hizmet_3:hover .hizmet_card .card-footer{
    background-color: #3b8cfe;
    color: #fff;
}
.hizmetler a.hizmet_1:hover .hizmet_card{
    box-shadow: 0 10px 30px 5px rgba(119, 0, 238, .2) !important;
}
.hizmetler a.hizmet_2:hover .hizmet_card{
    box-shadow: 0 10px 30px 5px rgba(255, 191, 87, .3) !important;
}
.hizmetler a.hizmet_3:hover .hizmet_card{
    box-shadow: 0 10px 30px 5px rgba(59, 140, 255, .3) !important;
}
.hizmet_card{
    position: relative;
    margin: 30px 0;
    border: none;
    -webkit-box-shadow: 0 0 1rem rgb(160 59 255 / 25%);
    box-shadow: 0 0 1rem rgb(160 59 255 / 25%);;
    -o-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.hizmet_card .card-title{
    text-align: center;
    border-bottom: 1px solid #eee;
}
.hizmet_card .hizmet_img{
    margin: 10px 0 10px;
}
.hizmet_card .hizmet_img img{
    max-width: 70%;
    margin: 0 auto;
    display: block;
}
.hizmet_card h3{
    font-size: 22px;
    line-height: 60px;
    margin-bottom: 0;
}
.hizmet_card p{
    color: #707070;
}
.hizmet_card .card-body{
    padding: 0px 30px;
}
.hizmet_card .card-footer{
    background-color: #fff;
    color: #333;
    font-weight: 400;
    text-align: center;
    -o-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border-top: 1px solid #eee;
}