

.menu{
    margin-left: auto;
    margin-top: 40px;
}

.menu a{
    color: black;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.menu a:hover{
    color: #AFC2ED;
}

.menu a:not(:last-child){
    margin-right: 30px;
}

.menu a.active{
    border-bottom: 2px solid #AFC2ED;
    padding-bottom: 5px;
}

.top-info{

    width: 50%;
}

.title{
    font-size: 42px;
    font-weight: 900;
    line-height: 55px;
}

.info{
    font-size: 14px;
    color: #676262;
    line-height: 25px;
    margin-top: 30px;
}

.flex{
    display: flex;
    flex-wrap: nowrap;
}

button{
    cursor: pointer;
}

.prime-btn{
    width: 140px;
    height: 35px;
    color: white;
    border:none;
    font-weight: 600;
    box-shadow: none;
    background: #AFC2ED;
    border-radius: 999px;
    transition: background-color 0.3s ease;
}

.prime-btn:hover{
    background: #91a4d1;
}

.sec-btn{
    width: 140px;
    height: 35px;
    color: #AFC2ED;
    border:1px solid #AFC2ED;
    font-weight: 600;
    box-shadow: none;
    background: transparent;
    border-radius: 999px;
    transition: all 0.3s ease;
}

.sec-btn:hover{
    border-color: #91a4d1;
    color: #91a4d1;
}

.top-btns{
    margin-top: 100px;
    height: 300px;
}

.top-btns .sec-btn{
    margin-left: 15px;
}



body {


    transform-style: preserve-3d;

}

.containeres {
    width: 200px;
    height: 1000px;
    position: relative;
    perspective: 1000px;
}

.box {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #3498db;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: Arial, sans-serif;
    border: 2px solid #2980b9;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform-origin: center center;
    transform: rotateX(${rotateX} deg) rotateY(${rotateY} deg);
    transition: transform 0.5s;

}
@import url("https://fonts.googleapis.com/css?family=Montserrat");



p {
    text-align: center;
}



.wrapper {
    position: absolute;
    /*top: 50%;*/
    /*left: 50%;*/
    background-image: url("https://ik.imagekit.io/lgf1wyqnvg/verivizyon/Telefon%20(2)_upscayl_1x_ultrasharp.png?updatedAt=1714857561853");
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(-50%, -50%) perspective(600px) rotateY(20deg)
    rotateX(10deg);
    transform-style: preserve-3d;
    width: 400px;
    height:500px;
    border-radius: 10px;
    margin-left: 50%;
margin-top: 30%;

}
.shape {
    background: url("https://ik.imagekit.io/lgf1wyqnvg/icons.svg?updatedAt=1714762691916");
    border-radius: 10px;
    width: 400px;
    height: 500px;
    transform: translateZ(50px) scale(0.85);
    opacity: 0.8;

}

.shape2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 250px;
    height: 350px;
    transform: translateZ(150px) translateX(-27px) translateY(-26px) scale(0.55);
    border: 3px solid rgba(255, 255, 255, 1);

    border-radius: 8px;
    opacity: 0.3;
}
@import url('https://fonts.googleapis.com/css?family=Varela+Round');

@import url('https://fonts.googleapis.com/css?family=Raleway');

a {
    text-decoration: none;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.home
{
    display: flex;
    align-items: center;
    width: 100%;
    height: 600px;
    background:#fff;
}
.prod-left {
    width:70%;
    text-align:left;
    padding-left:100px;
}
.prod-right {
    margin-left:100px;
    margin-top:50px;
    width: 45%;
}
.prod-left p
{
    margin:10px 0;
    font-size:13pt;
}
.prod-head {
    font-family: 'Nunito Sans', sans-serif;
    text-align: left;
    font-weight: 400;
    font-size: 30pt;
    margin: 10px 0;
}
.prod-head-sub {
    font-size: 14pt !important;
    color: #000;
    margin: 0 auto;
    line-height:1.5;
}
.prod-head-sub1 {
    font-family: 'Roboto', sans-serif;
    font-size: 15pt !important;
    color: #0074c5;
    margin: 40px 0 0 0 !important;
    line-height:1.5;
}


.prod-header
{
    z-index:1;
    position: fixed;
    top: 0;
}
.main-nav {
    background: transparent;
    display:flex;
    flex-direction:row;
    align-items:center;

}
.main-nav a {
    font-family: 'Raleway', sans-serif;
    color: #000;
    font-size:14pt;
    font-weight:600;
    background:black;
    margin:10px 0;
    padding:0 5px;
}
.nav-li
{

    cursor:pointer;
    line-height:2;
    padding:0 25px;
    margin:10px 0;
}
.li-h a
{
    color: #0074c5 !important;
}

.prod-feedback
{
    z-index:1;
    position: fixed;
    bottom:0;
    left:0;
    transform: rotate(-90deg) translateY(-75px) translateX(80px);
}
.side-nav a
{
    color: #0074c5;
    font-size:10pt;
    padding:0 ;
}
.side-nav  .nav-li
{
    padding:0 5px;
}
