*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.rowdsd {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    clear: both;
}

#imea {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    max-width: 300px;
    max-height: 300px;

}



.gallery {
    padding: 10px 0 300px;
    position: relative;
    overflow: hidden;
}

.gallery ul {

    position: relative;
}

.gallery ul li {
    float: left;
    margin: 0 0.8771929824561403%;

    width: 23.24561403508772%;
    position: relative;
}

.gallery ul li a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 0;
}

.gallery ul li a:before {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 40%;
    left: 40%;
    margin:  2px 2px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9UlEQVR4nO2ZXQrCMBCEcxqTu9jiAcyeRgoepdmeobZX8ud9xSooQpMoliQ4A/M+08mXPkQpCIK+0oq6jbY8auKLIZaU1lMGN2jb1h+V0MT71OHNvJvoJTIIK353VXgNy2P6oOy35UPMsTrnX8SdgkWSh6Q4o4jJYAWDReh3X674RRSKMBaZhKNFYERwa/kERgiMSDJG1MIyKPIiwE6AXfBD9AmMEBgRMOITGCEwIkUwYhayQhFKv4L5y0V0CQ89xMeIRdyQQVAJuA8vYts6g6Di9ZbXwSIPTprkYWnObhdV4lmmq26vpzkwo+8Z+uglIAhS77oCTDwZHy0nOhEAAAAASUVORK5CYII=) no-repeat;
    content: '';
    opacity: 0;
    z-index: 1;
    transition: all 0.3s linear;

}

.gallery ul li a:hover:before {

    top: 40%;
    opacity: 1;
    background-color: #ffffff;
    border-radius: 20%;
}

.gallery ul li a:after {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;

    content: '';
    opacity: 0;
    transition: all 0.3s linear;
}

.gallery ul li a:hover:after {
    opacity: 0.1;
}

.port {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 100px;
    background: #ffffff;
    background-color: #fafafa;
    z-index: 103;
    visibility: hidden;
    transform: translateY(-100%);
    border-bottom: 1px solid #d0d0d0;
    transition: all 0.5s ease-in-out;
}
.imeas{
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    max-width: 400px;
    max-height: 400px;
}

.port .imeas {
    width: 50%;
}

.port .description {
    float: left;
    width: 50%;
    max-height: 100%;
    padding: 0 40px 40px;
    overflow: auto;
}

.port .description h1 {
    font-size: 35px;
    line-height: 2.3;
    padding: 0;
}

.port .description > * {
    opacity: 0;
    transition: all 0.5s linear;
}

.port.item_open {
    visibility: visible;
    transform: translateY(0%);
    transition: all 0.4s ease-in-out;
}

.port.item_open .description > * {
    opacity: 1;
    transition-delay: 0.5s;
}

.close {
    width: 21px;
    height: 21px;
    background: url(data:image/svg+xml;utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2017.1.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0A%3Csvg%20version%3D%221.1%22%0A%09%20id%3D%22svg2%22%20xmlns%3Adc%3D%22http%3A//purl.org/dc/elements/1.1/%22%20xmlns%3Acc%3D%22http%3A//creativecommons.org/ns%23%22%20xmlns%3Ardf%3D%22http%3A//www.w3.org/1999/02/22-rdf-syntax-ns%23%22%20xmlns%3Asvg%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Asodipodi%3D%22http%3A//sodipodi.sourceforge.net/DTD/sodipodi-0.dtd%22%20xmlns%3Ainkscape%3D%22http%3A//www.inkscape.org/namespaces/inkscape%22%20inkscape%3Aversion%3D%220.48.4%20r9939%22%20sodipodi%3Adocname%3D%22icon-fullscreen.svg%22%0A%09%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2021%2021%22%0A%09%20enable-background%3D%22new%200%200%2021%2021%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cg%20id%3D%22layer1%22%20transform%3D%22translate%280%2C-1031.3622%29%22%3E%0A%09%3Cpath%20id%3D%22path2987%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20d%3D%22M0%2C1031.4l21%2C21%22/%3E%0A%09%3Cpath%20id%3D%22path2989%22%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20d%3D%22M21%2C1031.4l-21%2C21%22/%3E%0A%3C/g%3E%0A%3C/svg%3E%0A%0A) no-repeat;
    position: absolute;
    right: 10px;
    top: -121px;
    opacity: 1;
    z-index: 1004;
    transition: all 0.1s ease-in-out;
}

.item_open .close {
    opacity: 1;
    top: 0px;
    transition: all 0.3s ease-in-out;
}
