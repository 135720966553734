/*h1, h2, h3, h4, h5, h6 {*/
/*    color:#285daa;*/
/*}*/
a, a:hover, a:focus, a:active {
    text-decoration: none;
    outline: none;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.heading {
    margin-top: 0px;
}
.separator {
    position: relative;
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 30px;
}
.separator:after,
.separator:before {
    position: absolute;
    content: "";
    width: 50px;
    height: 2px;
    background: #f1f2f6;
    top: 50%;
}

.separator .iconsed {
    color: #f1f2f6;
}

.separator:after {
    right: 140%;
}

.separator:before {
    left: 140%;
}

.amazing_feature {
    padding-top:80px;
    padding-bottom:50px;
    background: #f1f2f6;
}

.single_feature{
    background: #f1f2f6 none repeat scroll 0 0;
    /*box-shadow:0 2px 30px rgba(0, 0, 0, 0.1);*/
    margin-bottom: 30px;
    padding: 40px 40px;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.single_feature:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #285daa;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.single_feature:hover, .single_feature:focus, .single_feature:active {
    color: white;
}
.single_feature:hover:before, .single_feature:focus:before, .single_feature:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.feature_icon{}
.single_feature .iconsed {

    /*border: 1px solid #e8e8e9;*/
    border-radius: 15%;
    color: #285daa;
    float: left;
    font-size: 10px;
    height: 70px;
    line-height: 70px;
    margin-right: 15px;
    position: relative;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 70px;
    z-index: 3;
    margin-top: 25px;

}
.single_feature:hover .iconsed{
    background:#285daa;color:white;
}

.single_feature h3 {
    text-transform: capitalize;
    font-size: 20px;
    font-weight:600;
    margin-top:0;
    overflow: hidden;
}
.single_feature:hover h3{color:#fff;}
.single_feature span {
    border-bottom: 1px dashed #ccc;
    display: block;
    margin: 15px auto 10px;
    width: 80px;
}
.single_feature p { margin-bottom: 0 ;overflow: hidden;}