.section-bg {
    background-color: #f1f2f6;
}
h2, .h2 {
    /* font-size: 2.5rem; */
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    color: #2f2f41;
    font-weight: 700;
    line-height: 1.3;
}
.h2, h2 {
    font-size: 2rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}
.font-w-4 {
    font-weight: 400;
}
.fs-18 {
    font-size: 18px !important;
}
.lead {
    font-size: 1.25rem;
    font-weight: 300;
}
.mb-0, .my-0 {
    margin-bottom: 0 !important;
}
.mb-0, .my-0 {
    margin-bottom: 0 !important;
}
.lead {
    font-size: 1.25rem;
    font-weight: 300;
}
p {
    line-height: 1.7;
}