.feature-hover:before {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 30% 90% 20% 30% / 30% 90% 30% 30%;
    box-shadow: 0 10px 30px 5px rgba(1, 164, 121, .06);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.80s, transform 0.80s;
}

.feature-hover:hover:before, .feature-hover.active:before {
    opacity: 1;
    transform: scale(1)
}

.feature-hover:hover .f-icon {
    animation: headShake 1.2s infinite;
    animation-duration: 5s;
    color: #285daa;
    position: relative;
}

.f-icon {
    font-size: 3.5rem;
    line-height: 3.5rem
}

.f-icon-m {
    font-size: 2.7rem;
    line-height: 2.7rem
}

.f-icon-s {
    font-size: 2rem;
    line-height: 2rem
}

.list-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
}
