.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: black;
    outline: 0;
    box-shadow: 0px 0px 10px 6px rgb(111 28 255 / 30%);
    transform: scale(1.02);
}

.form-group {
    margin-bottom: 1.3rem;
    position: relative;
}

.help-block.with-errors {
    position: absolute;
    color: red;
    right: 15px;
    font-size: 10px;
    line-height: 14px;
}